import { useModal } from "lib/modal"
import { useTranslation } from "lib/i18n/translation"

export default ({mode}) => {

	const { hide } = useModal()
	const { c } = useTranslation("common")

	return (
		<>
			{ mode == "off"
				? <p className="excerpt">{c("notification-removed")}</p>
				: <p className="excerpt">{c("notification-created")}</p>
			}
			<p className="centered">
				<a href="#" onClick={e => { e.preventDefault(); hide() }} rel="nofollow noopener" className="button">{c("close")}</a>
			</p>
		</>
	)
}
