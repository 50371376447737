import moment from "moment"
import image, { PRODUCT_PHOTOGRAMME } from "lacinetek-common/src/images"

import { useTranslation } from "lib/i18n/translation"
import classification from "lib/tools/classification"
import { useCountry } from "lib/country"
import { useVideoPlayer } from "lib/video-player"
import { useAuth } from "lib/auth"
import { RENT } from "lib/tools/product-info"

import SkeletonLink from "components/common/skeleton-link"
import ProductAccessLink from "components/common/product-access-link"
import Products from "components/common/products"
import Image from "components/common/image"

const getStatus = (access) => {
	let status = {
		label: "watch-the-movie",
		style: "none",
		width: 0
	}

	const video = access.videos.items
		.reduce((previousValue, currentValue) => {
			if (!previousValue) {
				return currentValue
			}

			if (currentValue.userStats?.plays > previousValue.userStats?.plays) {
				return currentValue
			}

			return previousValue
		}, null)

	if (!video || !video.userStats || video.userStats.plays === 0) {
		return status
	}

	const seek = video.userStats.seek / 60

	if (seek === 0 || (video.duration - seek < 1)) {
		status = {
			label: "rewatch-film",
			style: "done",
			width: 100
		}

		return status
	}

	status = {
		label: "continue-film",
		style: "in-progress",
		width: Math.round(seek / video.duration * 100)
	}

	return status
}

const isBought = (access, user) => user.accesses.items
	.filter(a => a.product
		&& access.product
		&& a.product.id === access.product.id
	)
	.map(a => a.accessMode.type)
	.includes("BUY")

const getVideoForAquiredAccessType = (access) => {
	if (access.accessMode.quality === "HD") {
		return access.product.extension.videos.items.find(video => video.accessInfo.qualityHD)
	}

	return access.product.extension.videos.items.find(video => video.accessInfo.qualitySD)
}

const filterRentedFilms = (access, user) => {

	if (access.accessMode.type === "BUY") {
		return false
	}

	if (moment(access.dateExp).isBefore(moment())) {
		return false
	}

	const videoForAccessType = getVideoForAquiredAccessType(access)

	if (!videoForAccessType) {
		return false
	}

	if (moment(videoForAccessType.accessInfo.expires).isBefore(moment())) {
		return false
	}

	if (isBought(access, user)) {
		return false
	}

	return true
}

const filterForCategory = (access, key, user) => {
	if (key == RENT) {
		return filterRentedFilms(access, user)
	}

	return access.accessMode.type == "BUY"
}

const removeDuplicatedAccesses = (accesses) => accesses.reduce((uniqAccesses, accessItem) => {
	if (!uniqAccesses.find(uniqAccessItem => uniqAccessItem.product.id === accessItem.product.id)) {
		uniqAccesses.push(accessItem)
	}

	return uniqAccesses
}, [])


const cleanAccessesForCategory = (accesses, menuKey, user) => {
	const accessesWithoutDuplicates = removeDuplicatedAccesses(accesses)

	return accessesWithoutDuplicates.filter(access => filterForCategory(access, menuKey, user))
}

const parseProductDeadline = (access) => {
	const videoForAccessType = getVideoForAquiredAccessType(access)

	if (!videoForAccessType) {
		return null
	}

	const deadline = {
		days: moment(videoForAccessType.accessInfo.expires).diff(moment(), 'days', true),
		hours: moment(videoForAccessType.accessInfo.expires).diff(moment(), 'hours', true),
		minutes: moment(videoForAccessType.accessInfo.expires).diff(moment(), 'minutes', true)
	}

	if (deadline.days > 2) {
		return `${Math.ceil(deadline.days)}j`
	}

	if (deadline.hours > 1) {
		return `${Math.round(deadline.hours)}h`
	}

	return `${Math.round(deadline.minutes)}m`
}

const Film = ({ type, film, expData, status, dataMode }) => {
	const { c } = useTranslation("common")
	const { inEuro } = useCountry()
	const { seeFilm } = useVideoPlayer()

	const deadline = parseProductDeadline(expData)

	return (
		<li
			className={`item in-selection is-rented ${!inEuro && "disabled"}`}
			key={`film-category-${film.id}-${status.label}`}
		>
			<div className="item-content">
				<div className="film-link">
					<a
						href="#"
						onClick={e => { e.preventDefault(); seeFilm(film) }}
						title={c(status.label)}
						data-overlay="#overlay-player"
						className="video-link cover"
					>
						<span className={`watch-status ${status.style}`}>
							<span
								className="watch-status-bar"
								style={{ width: `${status.width}%` }}
							/>
							<small>{status.style}</small>
						</span>
						<span className="label">{c(status.label)}</span>
						<figure>
							<picture>
								<Image
									src={image(film, PRODUCT_PHOTOGRAMME)}
									alt={film.name}
								/>
							</picture>
						</figure>
					</a>
					<div className="infos">
						<h3 className="title">
							{!dataMode && <span>{film.name}</span>}
							{dataMode.includes('list') && <>{film.name}</>}
							{classification(film)}
						</h3>
						<span className="director">{c("by")} {film.director}</span>
						<span className="year">{film.year}</span>
						<span className="country">{film.origin}</span>
						<span className="duration">{film.duration}</span>
						{type === RENT && (
							<p className="fixed user-status">
								<span className="deadline">{deadline}</span>
							</p>
						)}
						<SkeletonLink href="/film/[id]" as={`/film/${film.linkRewrite}`}>
							<a title={c("see-single-film")} className="more-link">
								<span className="label">{c("see-single-film")}</span>
							</a>
						</SkeletonLink>
					</div>
					<span className="format">{expData.accessMode.quality}</span>
				</div>
			</div>
			<nav className="film-actions" aria-label={c("film-actions")}>
				<ProductAccessLink product={film} canDownload={true} />
			</nav>
		</li>
	)
}

const Category = ({ accesses, dataMode, menuKey, hasMoreToLoad }) => {
	const { user } = useAuth()

	const cleanAccesses = cleanAccessesForCategory(accesses, menuKey, user)

	const { c } = useTranslation("common")

	if (!hasMoreToLoad && cleanAccesses.length == 0) {
		return (
			<div className="description">
				{c("dont-have-movie-here-yet")}
			</div>
		)
	}

	return (
		<>
			<ul className={`grid ${dataMode} films user`}>
				<Products products={cleanAccesses.map(a => a.product)} returnBaseData={false}>
					{(products) => products.map((access, index) => {
						if (access.id === 9999999) return null

						return <Film
							type={menuKey}
							film={products.find(p => p.id == access.id)}
							expData={accesses.find(a => a.product.id == access.id)}
							status={getStatus(accesses.find(a => a.product.id == access.id).product.extension)}
							dataMode={dataMode}
							key={`user-film-access-${index}`}
						/>
					})}
				</Products>
			</ul>

			{hasMoreToLoad && (
				<div className="centered loader">
				</div>
			)}
		</>
	)
}

export default Category
