import * as Sentry from "@sentry/node"
import { useState, useEffect, useContext, createContext } from "react"
import moment from "moment"
import { useMutation, useApolloClient, useLazyQuery } from "@apollo/react-hooks"

import { useModal } from "lib/modal"
import { useAuth } from "lib/auth"
import { useCountry } from "lib/country"
import { cartProductAddedDataLayer, cartProductRemovedDataLayer, overlayDataLayer } from "lib/gtm/interactions"
import { useTranslation } from "lib/i18n/translation"

import Cart from "components/cart/cart"
import Footer from "components/cart/footer"
import { AddSvodToCart } from "components/cart/add-svod-to-cart"
import { AddTvodToCart } from "components/cart/add-tvod-to-cart"
import { AddBundleToCart } from "components/cart/add-bundle-to-cart"
import OfferCinepassForm from "components/modals/offer-cinepass-form"

const debug = require("debug")("app:cart")

export const SD_TYPE = "SD"
export const HD_TYPE = "HD"
export const RENT = "RENT"
export const BUY = "BUY"

import {
	FETCH_PREPAYMENTS,
	FETCH_CART,
	FETCH_GIFT
} from "lib/graphql/queries"

import {
	ADD_TO_CART,
	ADD_GIFT_TO_CART,
	UPDATE_GIFT,
	REMOVE_FROM_CART,
	CHECKOUT_CART_WITH_PREPAYMENT,
	FREE_CHECKOUT_CART,
	CHECKOUT_CART,
	ADD_CART_VOUCHER,
	CONSUME_GIFT,
	CONSUME_PROMO,
	RESET_CART
} from "lib/graphql/mutations"

export const CART_TYPES_PREPAYMENT = "PREPAYMENT"
export const CART_TYPES_SVOD = "SVOD"
export const CART_TYPES_TVOD = "TVOD"

export const CART_RULE_TYPES_SVOD = "subscription"

const cartContext = createContext()

export const CartConsumer = cartContext.Consumer

export const ProvideCart = ({ subscriptions, children }) => {
	const cart = useProvideCart(subscriptions)

	return <cartContext.Provider value={cart}>{children}</cartContext.Provider>
}

export const useCart = () => {
	return useContext(cartContext)
}

const useProvideCart = (readSubscriptions) => {
	const client = useApolloClient()
	const { countries, purchasable } = useCountry()

	let baseCart = null
	let subscriptions = readSubscriptions
		.filter((s) => s.extension.accessModes.length)
		.sort((s1, s2) =>
			s1.extension.accessModes[0].price > s2.extension.accessModes[0].price
				? 1
				: -1
		)

	useEffect(() => {
		client.query({ query: FETCH_CART }).then(({ data }) => {
			setCart(data.billing.cart || { content: { items: [] } })
		})
	}, [])

	useEffect(() => {
		if (!purchasable) {
			clearCart()
		}
	}, [purchasable])

	const {
		connecting,
		onSignedOut,
		removeOnSignedOut,
		user
	} = useAuth()

	const [addToCartMutation] = useMutation(ADD_TO_CART)
	const [addGiftToCartMutation] = useMutation(ADD_GIFT_TO_CART)
	const [removeFromCartMutation] = useMutation(REMOVE_FROM_CART)
	const [checkoutWithPrepaymentMutation] = useMutation(CHECKOUT_CART_WITH_PREPAYMENT)
	const [freeCheckoutCartMutation] = useMutation(FREE_CHECKOUT_CART)
	const [checkoutCartMutation] = useMutation(CHECKOUT_CART)
	const [addVoucherToCartMutation] = useMutation(ADD_CART_VOUCHER)

	const { show } = useModal()
	const [getPrepayments, { loading: loadingPrepayments, data: prepaymentsData, error: prepaymentsError }] = useLazyQuery(FETCH_PREPAYMENTS)
	const [prepayments, setPrepayments] = useState([])
	const [cart, setCart] = useState(baseCart || { content: { items: [] }, vouchers: [] })
	const [adding, setAdding] = useState(false)
	const [removing, setRemoving] = useState(null)

	useEffect(() => {
		onSignedOut("cart", checkCarts)
		return () => {
			removeOnSignedOut("cart")
		}
	}, [])

	useEffect(() => {
		if (!connecting) {
			checkCarts()
		}
	}, [connecting])

	useEffect(() => {
		if (loadingPrepayments || prepaymentsError || !prepaymentsData) {
			return
		}

		setPrepayments(prepaymentsData.cms.products.items)
	}, [loadingPrepayments, prepaymentsData, prepaymentsError])

	const fetchCart = (force = false) => {
		return client
			.query({
				query: FETCH_CART,
				fetchPolicy: force ? "network-only" : "cache-first"
			})
			.then((data) => {
				const {
					data: {
						billing: { cart }
					}
				} = data
				setCart(cart || { content: { items: [] } })

				return cart || { content: { items: [] } }
			})
	}

	const checkCarts = () => {
		fetchCart(true)
	}

	const { c } = useTranslation("common")
	const addToCart = (product, options = {}) => {
		switch (product.type) {
			case "TVOD":
			case undefined:
			case "Long métrage":
				overlayDataLayer(
					options && options.gift ? "Offrir le film" : "Ajouter au panier TVOD"
				)
				show({
					id: "add-film-to-cart",
					children: (
						<AddTvodToCart
							key={`add-tvod-to-cart-modal-${product.id}`}
							product={product}
							gift={options && options.gift}
							accessMode={options.accessMode}
						/>
					),
					className: "modal short"
				})
				break
			case "SVOD":
				overlayDataLayer("Ajouter au panier SVOD")
				show({
					id: "add-svod-to-cart",
					children: <div>{c("add-svod-to-cart")}</div>,
					className: "modal svod-color"
				})
				break
			case "BUNDLE":
				overlayDataLayer(
					options && options.gift
						? "Offrir le film"
						: "Ajouter au panier BUNDLE"
				)
				show({
					id: "add-film-to-cart",
					children: (
						<AddBundleToCart
							key={`add-bundle-to-cart-modal-${product.id}`}
							product={product}
							gift={options && options.gift}
							accessMode={options.accessMode}
						/>
					),
					className: "modal short"
				})
				break
			case "Cinepass":
				break
		}
	}

	const addVoucherToCart = (code) => {
		return addVoucherToCartMutation({ variables: { code } })
			.then(({ data: { addCartVoucher: cart } }) => {
				setCart(cart)
				return true
			})
			.catch((e) => {
				return false
			})
	}

	const addSVODToCart = (options) => {
		let availabilities = {}
		countries.forEach((country) => {
			availabilities[country.isoCode] =
				country.svod &&
				(!country.svod_from || moment(country.svod_from) >= moment)
		})
		overlayDataLayer("Offrir un abonnement")
		show({
			id: "add-svod-to-cart",
			children: (
				<AddSvodToCart product={{ countriesAvailability: availabilities }} />
			),
			className: "modal svod-color"
		})
	}

	const addGiftToCart = (product, options) => {
		show({
			id: "add-gift-to-cart",
			children: <div>{c("add-gift-to-cart")}</div>,
			children: <AddTvodToCart product={product} gift={true} />,
			className: "modal short"
		})
	}

	const addCinepassToCart = (options = {}) => {
		if (prepayments.length === 0) {
			getPrepayments()
		}

		if (purchasable || options.offer) {
			let availabilities = {}
			countries.forEach((country) => {
				availabilities[country.isoCode] = true
			})
			overlayDataLayer("Cinépass")
			show({
				id: "offer-modal",
				className: "modal tvod-color",
				children: (
					<OfferCinepassForm
						offer={options.offer}
						product={{ countriesAvailability: availabilities }}
					/>
				)
			})
		}
	}

	const addProductToCart = (product, options = {}, resetIfContent = true) => {
		if (!adding && (purchasable || options.gift)) {
			setAdding(true)
			const accessModes = product.extension
				? product.extension.accessModes
				: product.accessModes
			if (options.type && options.quality) {
				options.accessMode = accessModes
					.filter(
						(a) =>
							a.type == options.type &&
							(!a.onlyVideos ||
								a.onlyVideos.filter((o) => o.name.includes("Freebox"))
									.length !== a.onlyVideos.length)
					)
					.find((a) => a.type == options.type && a.quality == options.quality)
				if (options.accessMode) options.accessMode = options.accessMode.id
				else options.accessMode = parseInt(accessModes[0].id)
			} else if (accessModes && accessModes.length) {
				options.accessMode = parseInt(accessModes[0].id)
			} else {
				options.accessMode = 0
			}
			let variables = {
				product: parseInt(product.id),
				accessMode: options.accessMode || 0
			}

			const context = {
				headers: {}
			}

			if (options.gift) {
				variables = { ...variables, ...options.gift }

				context.headers["X-Forwarded-For"] = options.ip
			}

			const mutation = options.gift ? addGiftToCartMutation : addToCartMutation

			return mutation({ variables, context })
				.then(({ data: { addProductToCart: localCart } }) => {
					setCart(localCart)
					setAdding(false)
					setTimeout(() => {
						showCart()
					}, 500)
					cartProductAddedDataLayer({ cart: localCart, product, options })
				})
				.catch((err) => {
					setAdding(false)
					throw err
				})
		}
	}

	const removeFromCart = (product, options) => {
		setRemoving(product.id)
		return removeFromCartMutation({
			variables: {
				product: parseInt(product.id),
				accessMode: options.accessMode ? parseInt(options.accessMode.id) : 0
			}
		}).then(({ data: { removeProductFromCart: cart } }) => {
			setCart(cart)
			setRemoving(null)
			cartProductRemovedDataLayer({ product, options })
		})
	}

	const showCart = () => {
		overlayDataLayer("Panier")
		show({
			id: "cart",
			title: c("cart"),
			fixedHeader: true,
			children: <Cart tiny={true} />,
			footer: <Footer />,
			className: "short right slide min",
			footerClassName: "fixed"
		})
	}

	const orderProducts = () => {
		return {
			tvod: cart.content.items.filter((p) => p.product.type == "TVOD"),
			svod: cart.content.items.filter((p) => p.product.type == "SVOD"),
			pass: cart.content.items.filter((p) => p.product.type == "PREPAYMENT"),
			bundle: cart.content.items.filter((p) => p.product.type == "BUNDLE")
		}
	}

	const checkoutWithPrepayment = (balanceType) => {
		return checkoutWithPrepaymentMutation({ variables: { balanceType } })
			.then(
				({
					data: {
						checkoutWithPrepayment: { success }
					}
				}) => {
					return success
				}
			)
			.catch(() => {
				return false
			})
	}

	const checkoutWithPaypal = (intent = "URL", gateway = "PAYPAL") => {
		return checkoutCartMutation({ variables: { intent, gateway } })
	}

	const reset = () => {
		client.query({ query: FETCH_CART, fetchPolicy: "network-only" }).then(
			({
				data: {
					billing: { cart }
				}
			}) => {
				if (cart) {
					setCart(cart)
				} else {
					setCart({ content: { items: [] } })
				}
			}
		)
	}

	const clearCart = () => {
		return client
			.mutate({ mutation: RESET_CART })
			.then(({ data: { resetCart: localCart } }) => {
				setCart({ content: { items: [] } })
			})
	}

	const updateGift = (
		id,
		{ firstname, lastname, email, message, dateSend, custom }
	) => {
		return client
			.mutate({
				mutation: UPDATE_GIFT,
				variables: { id, firstname, lastname, email, message, dateSend, custom }
			})
			.then(({ data: { updateGift } }) => {
				setCart(updateGift)
			})
	}

	const [consumingGift, setConsumingGift] = useState(false)
	const [consumingPromo, setConsumingPromo] = useState(false)

	const consumeGift = (token) => {
		setConsumingGift(true)
		return client
			.query({
				query: FETCH_GIFT,
				variables: { token },
				context: { clientName: "admin" }
			})
			.then(
				({
					data: {
						gift: { id }
					},
				}) => {
					return client
						.mutate({ mutation: CONSUME_GIFT, variables: { id, token } })
						.then(({ data: { consumeGift } }) => {
							return consumeGift
						})
				}
			)
	}

	const consumePromo = (code) => {
		setConsumingPromo(true)
		return client
			.mutate({
				mutation: CONSUME_PROMO,
				variables: { code, userId: parseInt(user.profile.id) },
				context: { clientName: "admin" }
			})
			.then(({ data: { consumePromo } }) => {
				return consumePromo
			})
	}

	const freeCheckout = () => {
		return freeCheckoutCartMutation()
			.then(({ data }) => {
				if (!data) {
					return false
				}
				return data.checkout.success
			})
			.catch((e) => {
				return false
			})
	}

	let realTotalPrice = cart ? cart.totalPrice : 0
	let totalReduction = 0
	if (cart && cart.vouchers) {
		cart.vouchers.forEach((voucher) => {
			realTotalPrice += voucher.reductionAmount
			totalReduction += voucher.reductionAmount
		})
	}

	return {
		cart,
		realTotalPrice,
		totalReduction,
		subscriptions,
		getPrepayments,
		loadingPrepayments,
		prepayments,
		addToCart,
		addVoucherToCart,
		fetchCart,
		addSVODToCart,
		addGiftToCart,
		addProductToCart,
		addCinepassToCart,
		updateGift,
		checkoutWithPrepayment,
		freeCheckout,
		checkoutWithPaypal,
		removeFromCart,
		showCart,
		orderProducts,
		reset,
		clearCart,
		adding,
		removing,
		consumeGift,
		consumingGift,
		setConsumingGift,
		consumePromo,
		consumingPromo,
		setConsumingPromo,
	}
}
