import { useState } from "react"
import { useTranslation } from "lib/i18n/translation"
import { useModal } from "lib/modal"
import { useApolloClient } from "@apollo/react-hooks"
import { TOGGLE_ACCESS_MUTATION } from "lib/graphql"
import { useAccesses } from "lib/accesses"

export default ({ access, renew }) => {
	const { c } = useTranslation("common")
	const { updateRecurringStateOfSubscription } = useAccesses()
	const { hide } = useModal()
	const client = useApolloClient()

	const [terminating, setTerminating] = useState(false)

	const terminate = async () => {
		const result = !access.recurring
		setTerminating(true)

		const { data } = await client.mutate({ mutation: TOGGLE_ACCESS_MUTATION, variables: { id: access.id } })
		updateRecurringStateOfSubscription(data.toggleAccessRenewal)

		setTerminating(false)
		hide()
	}

	return (
		<>
			<h2 className="overlay-title">{c(`confirm-terminate${renew ? "-renew" : ""}`)}</h2>
			<p className="excerpt">{c(`confirm-terminate${renew ? "-renew" : ""}-desc`)}</p>
			<p className="centered">
				<a
					href="#"
					onClick={e => {
						e.preventDefault();
						hide()
					}}
					rel="nofollow noopener"
					title={c("cancel")}
					className="button"
					style={{ marginRight: "1rem" }}
				>
					{c("cancel")}
				</a>
				<a
					href="#"
					onClick={async (e) => {
						e.preventDefault();
						await terminate()
					}}
					rel="nofollow noopener"
					title={c(`terminate${renew ? "-renew" : ""}`)}
					className={`button ${terminating ? "is-loading" : ""}`}
				>
					{c(`terminate${renew ? "-renew" : ""}`)}
				</a>
			</p>
		</>
	)
}
