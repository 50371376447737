import { useContext, createContext, useEffect, useState } from "react"
import { useApolloClient } from "@apollo/react-hooks"
import moment from "moment"

import { ANNUAL_PLUS } from "lib/tools/subscriptions-list"
import { useAuth } from "lib/auth"
import { useCountry } from "lib/country"
import {
	FETCH_ACCESS,
	FETCH_ACCESSES_SVOD,
	FETCH_ACCESSES_AMOUNT
} from "lib/graphql"
import { MONTHLY } from "lib/tools/subscriptions-list"
import { wait } from "lib/wait"

const debug = require("debug")("lacinetek:accesses")

const accessesMap = new Map()

const accessesContext = createContext()

export const ProvideAccesses = ({ children, country, countries }) => {
	const accesses = useProvideAccesses({ country, countries })

	return <accessesContext.Provider value={accesses}>{children}</accessesContext.Provider>
}

export const useAccesses = () => {
	return useContext(accessesContext)
}

function useProvideAccesses({ country, countries }) {
	const [hasSubscribed, setHasSubscribed] = useState(null)
	const [subscription, setSubscription] = useState(null)
	const [hasAccesses, setHasAccesses] = useState(null)

	const client = useApolloClient()
	const { user, onSignedIn, removeOnSignedIn, onSignedOut, removeOnSignedOut } = useAuth()
	const { inEuro, filterCountry } = useCountry()

	useEffect(() => {
		onSignedIn("subscription", fetchSubscription)
		onSignedIn("hasAccesses", checkHasAccesses)
		onSignedOut("reset-accesses", reset)

		return () => {
			removeOnSignedIn("subscription")
			removeOnSignedOut("reset-accesses")
		}
	}, [])

	const reset = () => {
		setHasSubscribed(null)
		setSubscription(null)
		accessesMap.clear()
	}

	const fetchSubscription = (user) => {
		if (!user) {
			return Promise.resolve()
		}

		const today = moment().format("YYYY-MM-DD")
		const products = country.subscriptions.map(subscription => subscription.id)

		return client
			.query({
				query: FETCH_ACCESSES_SVOD,
				variables: {
					query: `type: SVOD AND dateExp: {${today} TO *} AND product: (${products.join(" OR ")})`
				},
				fetchPolicy: "network-only"
			})
			.then(({ data }) => {
				if (!data.user) {
					setHasSubscribed(null)
					return
				}

				if (data.user.accesses.items.length < 1) {
					setHasSubscribed(false)
					return
				}

				setSubscription(mapUserAccess(data.user.accesses.items[0]))
				setHasSubscribed(true)
			})
	}

	const refetchSubscription = (user) => {
		setHasSubscribed(null)
		accessesMap.clear()
		return fetchSubscription(user)
	}

	const mapUserAccess = (access) => {
		const countrySubscription = filterCountry.subscriptions.find(countrySubscription => countrySubscription.id == access.product.id)
		const isMonthly = countrySubscription ? countrySubscription.key.includes(MONTHLY) : false

		return {
			...access,
			isMonthly
		}
	}

	const hasAccessToProduct = (product) => {
		return new Promise((resolve, reject) => {
			if (!inEuro) {
				resolve(false)
				return
			}

			if (!user) {
				resolve(false)
				return
			}

			if (accessesMap.has(product.id)) {
				resolve(accessesMap.get(product.id))
				return
			}

			debug(`Fetch access to product ${product.id} (${product.name})`)
			client.query({
				query: FETCH_ACCESS,
				variables: {
					id: product.id
				},
			})
				.then(({ data }) => {
					debug(`Fetched access data to product ${product.id} (${product.name})`)
					let access = false
					if (data.cms.products.items[0]?.extension.user?.accesses?.items?.length !== 0) {
						access = true
					}

					accessesMap.set(product.id, access)
					resolve(access)
				})
				.catch(error => reject(error))
		})
	}

	const hasAccessToAllProductsInBundle = async (bundle) => {
		if (!bundle || !bundle.extension || !bundle.extension.products) return false

		let hasTheAccesses = true

		for (const product of bundle.extension.products.items) {
			const res = await hasAccessToProduct(product)

			if (!res) {
				hasTheAccesses = false

				break
			}
		}

		return hasTheAccesses
	}

	const updateRecurringStateOfSubscription = (recurringState) => {
		setSubscription({
			...subscription,
			recurring: recurringState
		})
	}

	const waitAccessToProduct = async (product, options = {}) => {
		const { delay = 1000, max = 30 } = options
		let access = false

		for (let index = max; index > 0; index--) {
			debug(`waitAccessToProduct ${index}`)
			try {
				access = await hasAccessToProduct(product)
				debug(`waitAccessToProduct ${index} ${access}`)
			}catch (error) {
				throw error
			}

			if (access) {
				break
			}

			// Need to delete product access to correctly test next iteration
			deleteAccessToProduct(product)

			// Wait {delay} milliseconds till next product access test
			await wait(delay)
		}

		if (!access) {
			throw new Error("Wait access Product too long")
		}

		debug("End waitAccessToProduct")
	}

	const deleteAccessToProduct = (product) => {
		accessesMap.delete(product.id)
	}

	const isSubscriptionAnnualPlus = (subscription) => {
		const annuals = countries
			.flatMap(country => country.subscriptions)
			.filter(s => s.key == ANNUAL_PLUS)
			.map(s => s.id.toString())

		return annuals.includes(subscription.product.id)
	}

	const checkHasAccesses = async () => {
		const { data: { user: { accesses: { pagination: { total } } } } } = await client.query({
			query: FETCH_ACCESSES_AMOUNT,
			fetchPolicy: "network-only"
		})

		setHasAccesses(total > 0)
	}

	return {
		refetchSubscription,
		updateRecurringStateOfSubscription,
		hasAccessToProduct,
		hasAccessToAllProductsInBundle,
		waitAccessToProduct,
		deleteAccessToProduct,
		isSubscriptionAnnualPlus,
		hasSubscribed,
		hasAccesses,
		subscription
	}
}
