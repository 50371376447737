
import { getToken, getRefreshToken } from "lib/graphql/apollo/token"

export default function openPlayer(videoId) {

	const accessToken = getToken()
	const refreshToken = getRefreshToken()

	loadScript("https://app.noop.fr/kinow/kinow-player-web.js")
		.then(function() {
			window.kinowPlayer = window.kinowPlayer.buildVariant("lacinetek", "prod")
			return window.kinowPlayer
				.openPlayer(refreshToken, videoId)
				.then(result => {
					if (result) {
						return
					}

					return window.kinowPlayer
						.downloadPlayer()
				})
				.catch((error) => { console.error(error)});
		})
}

const loadScript = function(url) {
	return new Promise(function(resolve, reject) {
		const script = document.createElement('script');
		script.src = url;

		script.addEventListener('load', function() {
				resolve(true);
		});

		document.head.appendChild(script);
	});
}
